import React from "react"
import PostApplyForm from "./post.applyForm"
import { Container } from 'vactory-ui'

const PostApplyFormContainer = ({pageContext: {node}}) => {
    return (
    <Container>
        <PostApplyForm post={node} />
    </Container>
    )
}

export default PostApplyFormContainer
