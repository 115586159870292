import React from "react"
import {Form, useWebform} from 'vactory-gatsby-webform';
import {
    Heading,
    Text,
} from 'vactory-ui'

const formStyles = {

    ReCaptchaField: {
        // does not work. 
    },

    fieldsGroup: {
        '> div:nth-child(-n+4)': [
            null, // xs
            { // sm
                display: 'inline-block',
                width: '50%',
            }
        ],
        '> div:nth-child(-2n+3)': [
            null,    
            {
                paddingRight: 10,
            }
        ],
        '> div:nth-child(-2n+4)': [
            null,
            {
                paddingLeft: 10,
            }
        ],
    },
}


const PostApplyForm = ({post}) => {
    const formId = post.field_tender.drupal_internal__id;
    const webform = useWebform(formId);

    return (
        <>
            <Heading mt={50} level={3}>{post.title}</Heading>
            <Text sx={{
                color: '#868e96',
                fontWeight: 'light',
                mb: 30,
            }}>Ref: {post.field_vactory_name}</Text>

            <Form
                webformId={formId}
                schema={webform.elements}
                styles={formStyles}
                buttons={{
                    reset: {
                        hidden: true,
                    }
                }}
            />
        </>
    )
};

export default PostApplyForm
